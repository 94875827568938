/* You can add global styles to this file, and also import other style files */

html {
    height: calc(100vh - 70px); // layout miatt
    scroll-behavior: smooth;
}

body.light .selected-row{
    background-color: #dae1f2;
}

body.dark .selected-row{
    background-color: #020910;
}



body.light .icon-shadow{
    filter: drop-shadow(1px 2px 2px #aaa);
}

body.dark .icon-shadow{
    filter: drop-shadow(1px 2px 2px #111);
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.color-primary {
    color: #033f80;
}

.color-secondary {
    color: #363b3f;
}

.color-success {
    color: #165c27;
}

.color-info {
    color: #0b4e5a;
}
.color-dark {
    color: #1a1d20;
}
.color-warning {
    color: #8f6d06;
}

.color-danger {
    color: #81222c;
}




mat-paginator-goto{
    // .mdc-notched-outline__leading {
    //     // border: none !important;
    //     border-top: none !important;
    //     border-left: none !important;
    //     border-radius: 0 !important;
    // }
    // .mdc-notched-outline__notch {
    //     border: none !important;
    // }
    // .mdc-notched-outline__trailing {
    //     // border: none !important;
    //     border-top: none !important;
    //     border-right: none !important;
    //     border-radius: 0 !important;
    // }



    
    // .mat-mdc-form-field.mat-primary .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field.mat-primary .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field.mat-primary .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    //     border-color: #fff445;
    //   }

    .mat-mdc-paginator-container{
        justify-content: center;
    }

    // .mat-mdc-paginator-page-size-select{
    //     // width: min-content;
    // }

    // .mat-mdc-form-field-infix{
    //     // width: min-content;
    //     // width: 76px;

    //     mat-select{
    //         // width: min-content;

    //     }
    // }
}

button i.fas{
    position:relative;
    top:1px;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input,
.mdc-text-field--filled.mdc-text-field--disabled .mat-mdc-select-disabled,
.order-status-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

////////////////////////////////////////////////////////////////////////// snackbar
.custom-snackbar.success {
    --mdc-snackbar-container-color: var(--mat-slider-ripple-color);
    --mdc-snackbar-supporting-text-color: #fff;
    padding: 16px;
}

.custom-snackbar.alert {
    // --mdc-snackbar-container-color: #e22525;
    --mdc-snackbar-container-color: var(--bs-red);
    --mdc-snackbar-supporting-text-color: #fff;
    padding: 16px;
}

.custom-snackbar.mdc-snackbar{
    position:relative;
    margin-top:55px;
    // --mat-snack-bar-button-color:#000000a9;
}

.custom-snackbar .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before{
    opacity: 0.3;
    // --mat-mdc-button-persistent-ripple-color: #fff;
    // --mat-mdc-button-persistent-ripple-color: rgba(255, 255, 255, 0.5);
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled){
    color: #ffffff !important;
    background-color: rgba(255, 255, 255, 0.18);
}
////////////////////////////////////////////////////////////////////////// snackbar

// .navbar-brand span {
//     padding-left: 2px;
//     position: relative;
//     top: -2px;
// }

button .feather-icon {
    height: 18px !important;
    width: 18px !important;
    position: relative;
    // top: -1px;
    left: -3px;
    top: -1px;
}

app-spinner-button .mdc-button__label .mat-icon{
    position: relative;
    height: 18px !important;
    width: 18px !important;
    left: -3px;
    top: 3px;
}


.menu .ng-scrollbar .ng-scroll-content{
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.multi-sort-table-settings{
    .table-settings{
        display: flex;
        flex-wrap: wrap;
        flex-flow: wrap-reverse;
        gap:4px;

        .table-settings-menu{
            margin: 8px 0 !important;
        }
    }

    .drag-chip-list{
        display:flex;
        gap:4px;
        flex-wrap: wrap;
    }
} 

.multi-sort-table-settings-grab-chip{
    cursor: move;
    display: inline-block;
}

.multi-sort-table-settings-toggle-arrow{
    cursor: pointer;
    display: inline-block;
    padding-left: 5px;
}

.breadcrumb-main .page-title {
    font-size: 24px;
}

.product-form-icon-container{
    top:-8px !important;

    .product-form-icon{
        font-size: 1.1rem;
    }
}
 
// .logo-white .navbar-header .logo-name {
//     color: var(--custom-primary-500);
//     font-weight: 400;
// }

.search-actions-table-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.multi-sort-table-header .mat-sort-header-content{
    text-align: left;
}

/* --------------------------------------- app-spinner --------------------------------------- */
.overlay-container{
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(240, 243, 251, 0.7);
    z-index: 9999;
  
    .loading {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0 auto;

        /* ---------- custom-mat-spinner ----------- */
        .custom-mat-spinner {
            -webkit-animation: custom-mat-spinner-rotate 2s linear infinite;
            animation: custom-mat-spinner-rotate 2s linear infinite;
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -32px 0 0 -32px;
            width: 100px;
            height: 100px;
        }
        .custom-mat-spinner .custom-mat-spinner-path {
            stroke: var(--custom-primary-500);
        
            -webkit-animation: custom-mat-spinner-dash 1.5s ease-in-out infinite;
            animation: custom-mat-spinner-dash 1.5s ease-in-out infinite;
        }
        
        @-webkit-keyframes custom-mat-spinner-rotate {
            100% {
            transform: rotate(360deg);
            }
        }
        
        @keyframes custom-mat-spinner-rotate {
            100% {
            transform: rotate(360deg);
            }
        }
        
        @-webkit-keyframes custom-mat-spinner-dash {
            0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
            }
            50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
            }
            100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
            }
        }
        @keyframes custom-mat-spinner-dash {
            0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
            }
            50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
            }
            100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
            }
        }
        /* ---------- index spinner----------- */
    }
}
/* --------------------------------------- app-spinner --------------------------------------- */

a {
    color: var(--custom-primary-500);
}

.form-control:focus {
    border-color: var(--custom-primary-500);
}

.product-form-icon-container{
    .product-form-icon{
        color: var(--custom-primary-500);
    }
}

.sidebar .menu .list li.active-top .menu-top {
    background-color: rgb(from var(--custom-primary-500) r g b / 0.16);
}

.search-actions-table-header{
    background-color: rgb(from var(--custom-primary-500) r g b / 0.16);
}

.custom-tbl-fav-edit {
    color: var(--custom-primary-500) !important;
}

.custom-tbl-fav-edit-disabled {
    color: lightgray !important;
}

.product-name{
    color: var(--custom-primary-500);
}


.navbar-header .navbar-brand{
    .logo-full {
        display: block;

        img{
            height:32px;
        }
    }
    .logo-small {
        display: none;
    }
}

.side-closed.submenu-closed .navbar-header .navbar-brand{
    .logo-full {
        display: none;
    }
    .logo-small {
        display: block;

        img{
            height:32px;
        }
    }
}

.kalam-light {
    font-family: "Kalam", cursive;
    font-weight: 300;
    font-style: normal;
}
  
.kalam-regular {
    font-family: "Kalam", cursive;
    font-weight: 400;
    font-style: normal;
}
  
.kalam-bold {
    font-family: "Kalam", cursive;
    font-weight: 600;
    font-style: normal;
}

.chart-tooltip-text-glow span{
    text-shadow: 0 0 1px #00000040, 0 0 1px #00000040, 0 0 1px #00000040, 0 0 1px #00000040, 0 0 1px #00000040, 0 0 1px #00000040, 0 0 1px #00000040;
}