@use '@angular/material' as mat;
@include mat.core();

$md-custom-primary: (
    50 : #eff7fc,
    100 : #d8ecf9,
    200 : #9dd0f2,
    300 : #5eb0e7,
    400 : #2691d8,
    500 : #0075c3,
    600 : #005ba2,
    700 : #003f76,
    800 : #002647,
    900 : #00101d,
    A100 : #d8ecff,
    A200 : #9dd0ff,
    A400 : #2691ff,
    A700 : #003fff,
        contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-custom-accent: (
    50 : #f3faf4,
    100 : #e3f4e5,
    200 : #bae5bf,
    300 : #8dd496,
    400 : #64bf6e,
    500 : #43a74f,
    600 : #2d8838,
    700 : #1c6225,
    800 : #103b15,
    900 : #071809,
    A100 : #e3ffe5,
    A200 : #baffbf,
    A400 : #64ff6e,
    A700 : #1cff25,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$my-app-primary: mat.define-palette($md-custom-primary);
$my-app-accent: mat.define-palette($md-custom-accent);
// $my-app-warn:    mat.define-palette($mat-deep-orange);
$my-app-theme: mat.define-light-theme((
    color: (
        primary: $my-app-primary,
        accent: $my-app-accent,
        // warn: $my-app-warn,
    )
));
$my-app-theme: map-merge($my-app-theme, (custom-colors: $my-app-theme));
@include mat.all-component-themes($my-app-theme);

:root{
    --custom-primary-50: #{mat.get-color-from-palette($my-app-primary, 50)};
    --custom-primary-100: #{mat.get-color-from-palette($my-app-primary, 100)};
    --custom-primary-200: #{mat.get-color-from-palette($my-app-primary, 200)};
    --custom-primary-300: #{mat.get-color-from-palette($my-app-primary, 300)};
    --custom-primary-400: #{mat.get-color-from-palette($my-app-primary, 400)};
    --custom-primary-500: #{mat.get-color-from-palette($my-app-primary, 500)};
    --custom-primary-600: #{mat.get-color-from-palette($my-app-primary, 600)};
    --custom-primary-700: #{mat.get-color-from-palette($my-app-primary, 700)};
    --custom-primary-800: #{mat.get-color-from-palette($my-app-primary, 800)};
    --custom-primary-900: #{mat.get-color-from-palette($my-app-primary, 900)};

    --custom-accent-50: #{mat.get-color-from-palette($my-app-accent, 50)};
    --custom-accent-100: #{mat.get-color-from-palette($my-app-accent, 100)};
    --custom-accent-200: #{mat.get-color-from-palette($my-app-accent, 200)};
    --custom-accent-300: #{mat.get-color-from-palette($my-app-accent, 300)};
    --custom-accent-400: #{mat.get-color-from-palette($my-app-accent, 400)};
    --custom-accent-500: #{mat.get-color-from-palette($my-app-accent, 500)};
    --custom-accent-600: #{mat.get-color-from-palette($my-app-accent, 600)};
    --custom-accent-700: #{mat.get-color-from-palette($my-app-accent, 700)};
    --custom-accent-800: #{mat.get-color-from-palette($my-app-accent, 800)};
    --custom-accent-900: #{mat.get-color-from-palette($my-app-accent, 900)};
}
